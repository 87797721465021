<template>
  <div class="ds-wrapper relative z-10">
    <vs-card class="ds-card mb-4 md:mb-6">
      <div slot="header" class="flex flex-row justify-between">
        <h4>Additional funding requests</h4>
      </div>
      <div>
        <div class="pt-5">
          <div v-for="(data, index) in topUps" :key="index">
            <div class="grid sm:grid-cols-3 gap-y-6 gap-x-20">
              <div>
                <div class="flex flex-col gap-y-2">
                  <p class="text-ancillary text-body-dark">Date requested</p>
                  <p class="text-primary">{{ data.createdAt }}</p>
                </div>
              </div>
              <div>
                <div class="flex flex-col gap-y-2">
                  <p class="text-ancillary text-body-dark">Last updated</p>
                  <p class="text-primary">{{ data.updatedAt }}</p>
                  <p class="text-primary" v-if="data.status == 'Submitted'">{{ data.createdByName }}</p>
                  <p class="text-primary" v-else>{{ getActionedByName(data) }}</p>
                </div>
              </div>
              <div>
                <div class="flex flex-col gap-y-2">
                  <p class="text-ancillary text-body-dark">Status</p>
                  <p>
                    <vs-chip :color="changeStatusColor(data.status)" size="small">{{ data.status }}</vs-chip>
                  </p>
                </div>
              </div>
              <div>
                <div class="flex flex-col gap-y-2">
                  <p class="text-ancillary text-body-dark">Amount / service fee</p>
                  <p class="text-primary">{{ formatCurrency(data.amount) }} / {{ formatCurrency(data.fee) }}</p>
                </div>
              </div>
              <div>
                <div class="flex flex-col gap-y-2">
                  <p class="text-ancillary text-body-dark">Notes</p>
                  <p class="text-primary">{{ data.notes }}</p>
                </div>
              </div>
              <div class="grid grid-cols-subgrid gap-4 col-span-3" v-if="data.attachments && data.attachments.length">
                <div class="flex flex-col gap-y-2">
                  <p class="text-ancillary text-body-dark">Supporting docs</p>
                  <div class="grid grid-cols-3">
                     <template v-for="(attachment, key) in data.attachments">
                      <S3FileGenerator :document="attachment" :key="key" />
                    </template>
                  </div>
                </div>
              </div>
            </div>
            <hr class="my-8 line-hr" v-if="showLine(index)" />
          </div>
        </div>
      </div>
    </vs-card>
  </div>
</template>

<script>
  import S3FileGenerator from "@/views/customer/components/S3FileGenerator";

  export default {
    props: ["topUps"],

    components: { S3FileGenerator },

    methods: {
      changeStatusColor(status) {
        let bgClass = "";

        switch (status) {
          case "Approved":
            bgClass = "success";
            break;
          case "Cancelled":
            bgClass = "secondary-500";
            break;
          case "Declined":
            bgClass = "error";
            break;
          case "Submitted":
            bgClass = "primary";
            break;
          default:
            bgClass = "primary";
            break;
        }
        return bgClass;
      },

      showLine(index) {
        return index < (this.topUps.length - 1);
      },

      getActionedByName(data) {
        let name = data.actionedByName;

        if ((data.actionedByRefModel == "Admin") || ["Approved", "Declined"].includes(data.status)) {
          name = process.env.VUE_APP_NAME.toLowerCase().includes("flip") ? "FlipPay" : "Rello";
        }

        return name;
      }
    }
  };
</script>

